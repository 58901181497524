import { LIBRARY_ITEM_TYPE, LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
import { LIBRARY_TAB, RENDER_TYPE_TAB, SEARCH_ROUTES } from '@/models/store/pixcapassets.interface';

export const BACK_TO_TOP_SCROLL_OFFSET = 200;
export const MALE_MANNEQUIN_URL = `${process.env.ROOT_URL}/cdn/library/assets/glb/male_mannequin.glb`;
export const FEMALE_MANNEQUIN_URL = `${process.env.ROOT_URL}/cdn/library/assets/glb/female_mannequin.glb`;
export const MALE_MANNEQUIN_FILE_ID = 'f5ab854f-d04c-47f2-a709-a0152f274c7e';
export const FEMALE_MANNEQUIN_FILE_ID = '9c2501c1-e9d7-41aa-a6c4-603c116cdec0';
export const STANDARD_GIF_FPS = 25;
export const LIBRARY_EXPLORER_TABS: LIBRARY_TAB[] = [LIBRARY_TAB.THREE_D_PACKS, LIBRARY_TAB.THREE_D_CHARACTERS, LIBRARY_TAB.MOCKUPS];
export const LIBRARY_SEARCH_EXPLORER_TABS: SEARCH_ROUTES[] = [
	// Individual items
	SEARCH_ROUTES.ITEM_ALL_ASSETS,
	SEARCH_ROUTES.ITEM_ELEMENTS,
	SEARCH_ROUTES.ITEM_CHARACTERS,
	SEARCH_ROUTES.ITEM_MOCKUPS,
	// Packs
	SEARCH_ROUTES.PACK_ALL_ASSET,
	SEARCH_ROUTES.PACK_ICONS,
	SEARCH_ROUTES.PACK_CHARACTERS,
	SEARCH_ROUTES.PACK_MOCKUPS,
];

export const LIBRARY_EXPLORER_RESOURCE_TYPES = [
	LIBRARY_RESOURCE_TYPE.MODELS,
	LIBRARY_RESOURCE_TYPE.CHARACTERS,
	LIBRARY_RESOURCE_TYPE.FRAMES,
	LIBRARY_RESOURCE_TYPE.TEXT,
	LIBRARY_RESOURCE_TYPE.TEXT_SHAPES,
	LIBRARY_RESOURCE_TYPE.SHAPES,
];

export const RENDER_TYPE_TABS: RENDER_TYPE_TAB[] = [RENDER_TYPE_TAB['3D_SCENES'], RENDER_TYPE_TAB.AI_SCENES];
export const BANNER_MODEL_SLUG_ID = process.env.PIXCAP_EXPLORE_BANNER_MODEL_SLUG;
export const BANNER_EXPLORE_TRY_NOW_SLUG = process.env.PIXCAP_EXPLORE_BANNER_EXPLORE_TRY_NOW_SLUG;
export const MATERIAL_LIBRARY_BANNER_MODEL_SLUG_ID = process.env.PIXCAP_MATERIAL_LIRARY_BANNER_MODEL_SLUG;
export const FOR_YOU_TOPIC = 'for-you';
export const LibraryMockupItemType: string[] = [LIBRARY_ITEM_TYPE.ANIMATED_MOCKUP_TEMPLATES, LIBRARY_ITEM_TYPE.STATIC_MOCKUP_TEMPLATES];
export const LibraryDesignItemType: string[] = [LIBRARY_ITEM_TYPE.DESIGN_TEMPLATES, LIBRARY_ITEM_TYPE.ANIMATED_DESIGN_TEMPLATES];
export const LibraryIconItemType: string[] = [
	LIBRARY_ITEM_TYPE.ANIMATED_MODELS,
	LIBRARY_ITEM_TYPE.TEXT_SHAPES,
	LIBRARY_ITEM_TYPE.STATIC_MODELS,
	LIBRARY_ITEM_TYPE.SHAPES,
	LIBRARY_ITEM_TYPE.FRAMES,
	LIBRARY_ITEM_TYPE.TEXT,
];
