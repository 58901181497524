import { AppLang } from '@/lang/_template.type';

const de: AppLang = {
	app_name: 'Pixcap',
	promo_banner: {
		title: 'Genießen Sie {discount}% Rabatt auf Pro- und Elite-Jahrespläne mit dem Promo-Code: <span class="text-weight-600">{promo_code}!</span>',
		cta_text: 'Jetzt {discount}% Rabatt sichern',
	},
	navbar: {
		search_placeholder: 'Suche...',
		search_dropdown: {
			recent_search: 'Letzte Suche',
			popular_search: 'Beliebte Suchen',
			suggested_search: 'Suchvorschläge',
		},
		navigation_items: {
			browse: 'Durchsuchen',
			asset: '3D-Assets',
			asset_dropdown: {
				icons_title: '3D-Symbole',
				icons_description: 'Über 15.000 kostenlose und Premium 3D-Symbole & Illustrationen',
				characters_title: '3D-Charaktere',
				characters_description: 'Über 1.000 kostenlose und Premium 3D-Charaktere',
				mockups_title: 'Mockups',
				mockups_description: 'Über 500 kostenlose und Premium Mockups und Branding-Mockup-Kits',
			},
			tools: 'Tools',
			tools_dropdown: {
				prefix: 'Beste KI-Tools',
				ai_3d_model: 'KI 3D-Modell-Generator',
				ai_character: 'KI-Charakter-Generator',
				ai_girl: 'KI-Mädchen-Generator',
				ai_background: 'KI-Hintergrund-Generator',
				ai_filter: 'KI-Filter',
				ai_icon: 'KI-Icon-Generator',
				icon_maker: '3D-Symbole-Maker',
			},
			pricing: 'Preise',
		},
		language_switcher: {
			tooltip: 'Sprache',
		},

		my_projects: 'Meine Projekte',
		login: 'Anmelden',
		notification_dropdown: {
			notifications: 'Benachrichtigungen',
			empty: 'Sie haben keine Benachrichtigungen',
		},
		download_dropdown: {
			full_download_history: 'Vollständiger Download-Verlauf',
			tooltip: {
				download_history: 'Downloadverlauf',
				download_completed: 'Download abgeschlossen',
			},
			show_all_downloads: 'Alle Downloads anzeigen',
			empty: 'Es ist leer hier. \n Versuche den Download (Hohe Qualität), \n um zu sehen, wo die Magie geschieht.',
			upgrade: {
				enjoy: 'Wusstest du, dass Pro/Elite-Benutzer <span class="text-color-pro">3-mal schnellere Renderzeiten</span> genießen?',
				text: 'Upgraden Sie jetzt für eine Geschwindigkeitssteigerung beim Download in hoher Qualität!',
				upgrade_now: 'Jetzt upgraden',
			},
			cancel_download_modal: {
				cancel_download: 'Hochwertigen Download abbrechen',
				text: 'Sie werden alle für dieses Video verwendeten Credits verlieren. Sind Sie sicher, dass Sie fortfahren wollen?',
				text_gif: 'Sie verlieren alle Credits, die Sie für dieses GIF verwendet haben. Sind Sie sicher, dass Sie fortfahren möchten?',
				confirm: 'Bestätigen',
			},
		},
		user_dropdown: {
			projects: 'Projekte',
			downloads: 'Downloads',
			account_settings: 'Kontoeinstellungen',
			invite_friends: 'Freunde einladen',
			contact_us: 'Kontaktiere uns',
			shared_project: 'Geteilte Projekte',
			contributor_files: 'Mitwirkendendateien',
			sign_out: 'Abmelden',
		},
		login_form: {
			get_started: 'Kostenlos loslegen',
			text: 'Greife auf über 15.000 anpassbare 3D-animierte Mockups, 3D-Symbole-Pakete, Illustrationen und Designs zu!',
			google_login: 'Mit Google fortfahren',
			email_login: {
				password: 'Passwort',
				forgot: 'Passwort vergessen?',
				email_login: 'Mit E-Mail fortfahren',
				verify_email: 'E-Mail verifizieren',
				check_email:
					'Überprüfe deine E-Mail unter <span class="text-color-primary-5">{{ email }}</span> auf einen Code zur Verifizierung deiner E-Mail-Adresse.',
				invalid_code: 'Der eingegebene Code ist ungültig, versuche es erneut',
				did_not_received: 'Keine E-Mail erhalten?',
				error_email: 'E-Mail ist ungültig',
				error_E02_02_001: 'E-Mail oder Passwort ist falsch.',
				error_E02_02_002: 'Diese E-Mail wird bereits verwendet',
				error_E02_02_003: 'Etwas ist schiefgelaufen, bitte versuchen Sie es erneut',
				error_default: 'Anmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
			},
		},
		forgot_pw_form: {
			forgot_pw: 'Passwort vergessen?',
			sub: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
			check_your_email: 'Überprüfen Sie Ihre E-Mail',
			sub_text: 'Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen des Passworts gesendet.',
			did_not_received: 'Keine E-Mail erhalten?',
			error_email: 'E-Mail ist ungültig',
			continue: 'Fortfahren',
			back: 'Zurück',
		},

		contact_us_form: {
			contact_us: 'Kontaktieren Sie uns',
			name: 'Name',
			enter_your_name: 'Geben Sie Ihren Namen ein',
			email: 'E-Mail',
			enter_your_email: 'Geben Sie Ihre E-Mail ein',
			message: 'Ihre Nachricht',
			write_message: 'Schreiben Sie Ihre Nachricht hier',
			send: 'Nachricht senden',
			error_name: '**Name darf nicht leer sein',
			error_email: '**E-Mail ist ungültig',
		},
	},
	sidebar: {
		home: 'Startseite',
		library: 'Bibliothek',
		my_projects: 'Meine Projekte',
		my_assets: 'Meine Assets',
		pricing: 'Preise',
		unlock_all_features: 'Alle Funktionen freischalten',
		upgrade_plan: 'Plan upgraden',
	},
	breadcrumb: {
		home: 'Startseite',
		mockups: 'Mockups',
		brandkit_mockups: 'Markenkit-Mockups',
		icon_packs: '3D-Symbole-Pakete',
		illustrations: 'Illustrationen',
		designs: 'Designs',
	},
	footer: {
		title: 'Entdecken Sie mehr 3D-Inhalte',
		searched_title: 'Relevante Suchanfragen durchsuchen',
		seo_section: {
			sort_by: {
				latest: 'Neueste',
				trending: 'Trendend',
			},
		},
		footer_section: {
			explore: {
				title: 'Erforschen',
			},
			features: {
				title: 'Funktionen',
				animated_mockup: 'Animierte Mockups',
				ai_style_transfer: 'AI-Stiltransfer',
				character_library: 'Charakterbibliothek',
				material_library: 'Materialbibliothek',
			},
			company: {
				title: 'Unternehmen',
				pricing: 'Preise',
				enterprise: 'Unternehmen',
				privacy_policy: 'Datenschutzrichtlinie',
				terms_of_service: 'Nutzungsbedingungen',
				contact_us: 'Kontaktieren Sie uns',
			},
			resources: {
				title: 'Ressourcen',
				glossary: 'Glossar',
				contributor: 'Werdet Mitwirkender',
			},
			tools: {
				title: 'Werkzeuge',
				ai_3d_model: 'AI 3D-Modell-Generator',
				ai_character: 'AI Charakter-Generator',
				ai_girl: 'AI Mädchen-Generator',
				ai_background: 'AI Hintergrund-Generator',
			},
			create: {
				title: 'Erstellen',
				mug_design: 'Tassendesign',
				sticker_design: 'Stickerdesign',
				friendsgiving_invitation: 'Freunde-Geburtstagseinladung',
				poster_mockup: 'Plakat-Mockup',
				iphone_mockup: 'iPhone-Mockup',
			},
			blogs: {
				title: 'Blogs',
				all_blogs: 'Alle Blogs',
			},
			follow_us: {
				title: 'Folgen Sie uns',
			},
		},
	},
	page: {
		home: {
			title: '3D-Assets für Designer',
			description:
				'Passe über 15.000 kostenlose und Premium 3D-Symbole, Illustrationen, Charaktere und Mockups für UX/UI, Grafikdesign und Marketing an.',
			partners: {
				title: 'Verwendet von Teams bei',
			},
			latest_icon_pack: 'Neueste 3D-Symbole',
			characters_pack: '3D-Charaktere',
			device_mockup_pack: 'Geräte-Mockups',
			branding_mockup_pack: 'Branding-Mockups',
			advertise: {
				title: 'Bestes 3D-Design-Tool für Ihre Projekte',
				description: 'Bearbeiten und verwenden Sie 3D-Modelle für Webdesign, mobile Apps, Marketing und mehr',
				first_card: {
					title: 'Website & mobile App',
					description:
						'Verwenden Sie 3D-Elemente, um das Design Ihrer Projekte zu verbessern. Fesseln Sie die Aufmerksamkeit Ihrer Nutzer und heben Sie Ihre Website und App von der Konkurrenz ab.',
				},
				second_card: {
					title: 'Animierte Mockups',
					description:
						'Laden Sie Ihr Design in ein beliebiges animiertes Mockup hoch, bearbeiten Sie den Hintergrund nach Ihren Wünschen und laden Sie es in weniger als 5 Minuten in 4K herunter.',
				},
				third_card: {
					title: 'Präsentation',
					description:
						'Nutzen Sie 3D-Charaktere und 3D-Symboles für Präsentationen. Machen Sie Ihr Geschäftspitch oder Ihre Bildungspräsentation ansprechender und dynamischer.',
				},
			},
			compatible: {
				title: 'Kompatibel mit Ihrer Lieblingssoftware',
				description: 'Laden Sie Pixcap-Inhalte in jedem Dateityp herunter - GLB, MP4, GIF, PNG zur Verwendung mit all Ihrer Lieblingssoftware.',
			},

			banner: {
				title_desktop: `Starten Sie, indem Sie beliebige <br> 3D-Inhalte anpassen!`,
				title_mobile: `Beginnen Sie mit der Bearbeitung <br> beliebiger 3D-Inhalte!`,
				button_text: 'Jetzt erkunden',
			},

			header1: 'Alle 3D-Goodies sind anpassbar - Probieren Sie es jetzt aus!',
			trending_icons: 'Trendige Icons',
			essential_icon: 'Essentielle Icon-Pakete',
			essential_illustrations: 'Essentielle Illustrations-Kits',
			essential_mockups: 'Realistische Geräte- und Branding-Mockups',

			header2: 'Entdecken Sie mehr bearbeitbare 3D-Kreationen',
			fintech_illustrations: 'Fintech-Illustrationen',
			ecommerce_illustrations: 'E-Commerce-Illustrationen',
			healthcare_illustrations: 'Gesundheitswesen-Illustrationen',
			browse_all_illustrations: 'Alle Illustrationen durchsuchen',
			business_characters: 'Geschäftsfiguren',
			casual_characters: 'Lässige Figuren',
			profession_characters: 'Berufsfiguren',
			browse_all_characters: 'Alle Figuren durchsuchen',
			laptop_mockups: 'Laptop-Mockups',
			smartphone_mockups: 'Smartphone-Mockups',
			branding_mockups: 'Branding-Mockups',
			browse_all_mockups: 'Alle Mockups durchsuchen',
		},
		library: {
			title: {
				icons: 'Passe kostenlose & <br> Premium 3D-Symbolpakete an',
				characters: 'Passe kostenlose & <br> Premium 3D-Charaktere an',
				mockups: 'Passe kostenlose & <br> Premium Mockups an',
			},
			description: {
				icons:
					'Wähle aus {count} hochwertigen Ikonensätzen für Website, App, Grafikdesign und Marketing. Passe Icons an und lade sie in PNG, GLB, GIF und MP4 für Designprojekte herunter.',
				characters:
					'Steigern Sie die Nutzerinteraktion und Storytelling mit Charakterillustrationen. Passen Sie sie an und verwenden Sie sie in Beiträgen in sozialen Medien, Präsentationen, Erklärvideos, Produktvorführungen und mehr.',
				mockups:
					'Verbessern Sie Ihre Designs und Präsentationen mit realistischen Mockups. Einfach anpassbar und perfekt zum Präsentieren von Produkten, Branding, Verpackungen und mehr.',
			},
			header: 'Entdecken Sie vollständig anpassbare 3D-Inhalte!',
			cards: {
				icons: {
					icons: 'Icons',
					browse_all: 'Alle 3D-Symbole durchsuchen',
				},
				characters: {
					characters: 'Figuren',
					browse_all: 'Alle 3D-Figuren durchsuchen',
				},
				illustrations: {
					illustrations: 'Illustrationen',
					browse_all: 'Alle 3D-Illustrationen durchsuchen',
				},
				mockups: {
					mockups: 'Mockups',
					branding_kits: 'Branding-Mockup-Kits',
					browse_all: 'Alle realistischen 3D-Mockups durchsuchen',
				},
				designs: {
					designs: 'Designs',
					browse_all: 'Alle 3D-Designs durchsuchen',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				'Inspirieren Sie sofort und präsentieren Sie das Design Ihrer Marke mit unserer Bibliothek professioneller Branding-Mockup-Vorlagen. Von eleganten Visitenkarten und auffälligen Verpackungen bis hin zu beeindruckenden Social-Media-Assets und Website-Mockups haben wir alles, was Sie brauchen, um die Identität Ihrer Marke in einer realistischen Umgebung zu visualisieren.',
			features: {
				premium_asset: 'Alle Premium-Assets',
				exclusive_materials: 'Exklusive Materialien & Voreinstellungen',
				exclusive_poses: 'Exklusive Charakterposen',
				commercial_license: 'Kommerzielle Lizenz',
				export: '3D-Dateiexport',
				edit: 'Farben, Materialien, Beleuchtung bearbeiten,...',
				downloads: 'Downloads in hoher Qualität',
				elite_unlimited_images: 'Unbegrenzte hochwertige Bilder',
				elite_video_per_month: '30 hochwertige Videos pro Monat',
			},
			get_all_access: 'Erhalten Sie vollen Zugriff',
			info: {
				info: 'Info',
				number_of_items: 'Anzahl der Elemente',
				file_type: 'Dateityp',
				compatible: 'Kompatibel mit',
				release: 'Veröffentlichung',
			},
			mockup_pack_description:
				'Das {pack_name}-Mockup-Paket ist eine Sammlung hochwertiger {pack_name}-Mockups, perfekt, um Ihre Designs professionell zu präsentieren. <br> Das {pack_name}-Mockup-Paket ermöglicht es Ihnen, Ihre Designs einfach anzupassen und zu präsentieren. Laden Sie einfach Ihr Design hoch, bearbeiten Sie Objekt und Hintergrund, um ein realistisches Mockup zu erstellen. <br> Alle {pack_name}-Mockups sind in PNG, GIF und MP4 für den Einsatz auf verschiedenen Plattformen verfügbar.',
			pack_description:
				'{pack_name} ist ein Satz von 3D-Symbolen und animierten Symbolen, perfekt für UX/UI, Grafikdesign und Marketing. <br> Das {pack_name} enthält eine große Vielfalt an Symbolen für all Ihre Bedürfnisse und behält ein einheitliches Farbschema für einen einheitlichen Look bei. <br> Alle {pack_name}-Symbole sind in PNG, GLB, GIF und MP4 für den Einsatz auf verschiedenen Plattformen verfügbar.',
			character_pack_description:
				'Das {pack_name}-Charakterpaket ist ein Satz von 3D-Charakteren und animierten Charakteren, perfekt für Websites, Animationen, Grafikdesign, Marketing und Präsentationen. <br> Das Paket bietet Anpassungsoptionen für jeden Charakter, so dass Sie den Hautton, die Haarfarbe, die 3D-Pose und mehr ändern können. <br> Alle {pack_name}-Charaktere sind in PNG, GLB, GIF und MP4 für den Einsatz auf verschiedenen Plattformen erhältlich.',
			browse_more: 'Entdecken Sie mehr bearbeitbare 3D-Kreationen',
			related_to_this_pack: 'Verwandt mit diesem Paket',
		},
		item_details: {
			features: {
				edit_character: 'Posen, Farben, Materialien, Beleuchtung bearbeiten...',
				edit_mockup: 'Bilder ersetzen, Farben, Materialien, Beleuchtung bearbeiten...',
				edit: 'Farben, Materialien, Beleuchtung bearbeiten...',
				downloads: 'Hochwertige Downloads',
				export: '3D-Dateien exportieren',
				ai: 'KI-Stilübertragung ausprobieren',
				realistic: 'Realistisches 3D-Mockup',
				editable: 'Bearbeitbares Design und Hintergrund',
				easy: `Einfach zu bedienende Werkzeuge`,
				high_quality: `Export in hoher Qualität`,
				free: 'Kostenlos für kommerzielle Nutzung',
				customize: 'Anpassbare Formen, Farben & Materialien',
				high_resolution: 'Hochauflösendes 3D-Design',
				file_types: 'PNG, GLB Dateitypen',
				free_license: 'Kostenlose Lizenz für kommerzielle und persönliche Projekte',
				ready_made: 'Vorgefertigte und anpassbare Vorlagen',
				easy_switch: 'Einfacher Wechsel zu verschiedenen Größen',
				file_types_2: 'PNG Dateityp',
				item_description:
					'{item_name} ist das perfekte Symbol für Web, Apps, UX/UI, Grafikdesign und Marketing. <br> Sie haben volle Anpassungsmöglichkeiten mit {item_name}, einschließlich Farbwechsel, Materialien und Stile, um sie an Ihre Designprojekte anzupassen. <br> Laden Sie {item_name} in PNG, GLB, GIF und MP4 herunter und verwenden Sie sie in Figma, Blender, Adobe und mehr.',
				character_description:
					'{item_name} ist der perfekte Charakter für Websites, Animationen, Grafikdesign, Marketing und Präsentationen. <br> Sie haben volle Anpassungsmöglichkeiten mit {item_name}, einschließlich Änderung des Hauttons, der Haarfarbe, der 3D-Pose und mehr. <br> Verfügbar in den Dateiformaten PNG, GLB.',
				mockup_description:
					'Dieses {item_name}-Mockup ist perfekt, um Ihre Marke, Ihr Logo, Ihr Website-Layout und mehr zu präsentieren. <br> Sie können das Objekt, den Hintergrund und Ihre eigenen Designelemente einfach anpassen, um es wirklich einzigartig zu machen. <br> Das {item_name}-Mockup ist in mehreren Dateiformaten wie PNG, GIF und MP4 für eine einfache Bearbeitung und Verwendung auf verschiedenen Plattformen verfügbar.',
			},

			open_in_mnm: 'In Mix-n-match öffnen',
			edit_this_asset: 'Diesen Asset bearbeiten',

			more_from_this_pack: 'Mehr aus diesem Paket',
			people_also_downloaded: 'Andere haben auch heruntergeladen',
			text_3d_graphic: '{item_name} 3D-Grafik',
			text_3d_illustration: '{item_name} 3D-Illustration',
			more_from_this_bundle: 'Vorlagen in diesem Bundle',
			related: 'Verwandte {resouce_type}',
		},
		search: {
			prefix_free: 'kostenlos',
			prefix_premium: 'premium',
			prefix_free_and_premium: 'kostenlos & premium',
			icon_packs: 'Symbol-Pakete',
			character_packs: 'Charakter-Pakete',
			mockup_packs: 'Mockup-Pakete',
			asset_packs: 'Asset-Pakete',
			icon: 'Symbol',
			icons: 'Symbole',
			character: 'Charakter',
			characters: 'Charaktere',
			mockup: 'Mockup',
			mockups: 'Mockups',
			asset: 'Asset',
			assets: 'Assets',
			description: {
				suffix_animated: 'Verfügbar in den Dateiformaten PNG, GLB, GIF, MP4.',
				suffix_pack: 'Probieren Sie animierte {search} {type}-Pakete aus, um die Aufmerksamkeit der Nutzer zu fesseln.',
				prefix_pack: 'Passen Sie an und laden Sie herunter',
				suffix_icon_packs: 'Pakete für Ihre nächsten Designprojekte.',
				suffix_character_packs: 'Pakete für Web, Apps, UX/UI, Grafikdesign und Marketing.',
				suffix_mockup_packs: 'Pakete für Präsentationen.',
				suffix_asset_packs: 'Pakete für Ihre nächsten Designprojekte.',
				suffix_item: 'Probieren Sie animierte {search} {type} aus, um die Aufmerksamkeit der Nutzer zu fesseln.',
				prefix_item: 'Passen Sie an und laden Sie herunter',
				suffix_icon_items: 'Symbole, Illustrationen, Grafiken und Logos für Ihre nächsten Designprojekte.',
				suffix_character_items: 'für Web, Apps, UX/UI, Grafikdesign und Marketing.',
				suffix_mockup_items: 'für Ihre nächsten Designprojekte.',
				suffix_asset_items: 'für Ihre nächsten Designprojekte.',
			},

			elements: 'Icons',
			packs: 'Pakete',
			illustrations: 'Illustrationen',
			designs: 'Designs',
			Elements: 'Icons',
			Packs: 'Pakete',
			Illustrations: 'Illustrationen',
			Mockups: 'Mockups',
			Designs: 'Designs',
			empty: 'Keine Sorge! Entdecken Sie andere Asset-Typen oder',
			request_new: 'fordern Sie neue an',
			cant_find: 'Nichts gefunden',
			browse_relevants: 'Durchsuchen Sie relevante Suchergebnisse',
		},
		my_projects: {
			title: 'Projekte',
			description: 'Passen Sie all Ihre Projekte ganz einfach an einem Ort an und verwalten Sie sie.',
			header: 'Meine Projekte',
			search_placeholder: 'Nach Design suchen...',
			new_design: 'Neues Design',
			default: 'Standard',
			advanced_editor: 'Erweiterter Editor',
			all: 'Alle',
			design: 'Design',
			video: 'Video',
			edited: 'Bearbeitet',
			duplicate_loading: 'Projekt wird dupliziert....',
			sort: {
				sort_by: 'Sortieren nach',
				recent: 'Neueste',
				alphabetical: 'Alphabetisch',
				oldest: 'Älteste',
			},

			menu: {
				open_in_advanced_editor: 'Im erweiterten Editor öffnen',
				duplicate: 'Duplizieren',
				duplicate_submission: 'Als Einreichung duplizieren',
				rename: 'Umbenennen',
				delete: 'Löschen',
			},

			empty_state: {
				title: 'Lass uns deine Projekte füllen!',
				description: 'Diese Seite ist jetzt leer. Es ist Zeit, 3D-Assets anzupassen.',
			},
		},
		my_assets: {
			title: 'Downloads',
			description: 'Sehen Sie hier alle Ihre heruntergeladenen Assets an. Sie können Ihre Assets jederzeit erneut herunterladen.',
			header: 'Meine Assets',
			search_placeholder: 'Datei suchen',
			sort: {
				sort_by: 'Sortieren nach',
				oldest: 'Älteste',
				newest: 'Neueste',
				alphabet_az: 'Alphabet (A-Z)',
			},
			type: {
				all: 'Alle Typen',
				default_render: 'Standard-Rendering',
				high_quality: 'Hohe Qualität',
				ai_generated: 'KI-generiert',
			},

			downloads: {
				downloads: 'Downloads',
				file: 'Datei',
				download_status: 'Download-Status',
				download_date: 'Download-Datum',

				empty_state: {
					title: 'Lass uns deine Downloads füllen!',
					description: 'Diese Seite ist jetzt leer. Es ist Zeit, coole Assets herunterzuladen.',
					text: 'Deine Download-Seite fühlt sich gerade etwas einsam an. Es ist der richtige Zeitpunkt, um coole Elemente zu exportieren.',
					heading_1: 'Entschuldigung, keine Ergebnisse gefunden',
					heading_2: 'Entschuldigung, wir konnten keine Übereinstimmungen für "{search}" finden',
					text_search: 'Versuche, deine Suche oder Filter anzupassen, um zu finden, wonach du suchst.',
					button: 'Mehr entdecken',
				},
			},
			purchases: {
				purchases: 'Käufe',
				file: 'Datei',
				purchase_date: 'Kaufdatum',
				open_file: 'Datei öffnen',
				empty_state: {
					title: 'Dein Einkauf ist leer.',
					text: 'Lass uns diesen Bereich mit deinen Artikeln füllen.',
					heading_1: 'Entschuldigung, keine Ergebnisse gefunden',
					heading_2: 'Entschuldigung, wir konnten keine Übereinstimmungen für "{search}" finden',
					text_search: 'Versuche, deine Suche oder Filter anzupassen, um zu finden, wonach du suchst.',
					button: 'Mehr entdecken',
				},
				order_no: 'Bestellnummer',
			},
		},
		upgrade_plan: {
			discount_text: 'Genieße {discount_program}: {discount}% Rabatt auf deinen Plan!',
			end_in: 'Zeitlich begrenztes Angebot endet in',
			header: '3D-Befähigung für Marken, Agenturen und Startups jeder Größe',
			is_pro:
				'Vielen Dank, dass Sie ein geschätzter Abonnent sind! Sie genießen unsere alten, niedrigeren Preise bis zu Ihrer nächsten Erneuerung zum neuen Tarif.',
			monthly: 'Monatlich',
			quarterly: '3 Monate',
			yearly: 'Jährlich',
			save_up: 'spare bis zu 33%',
			per_month: '/Monat',
			recommended: 'Empfohlen',
			current_short: 'Aktuell',
			current_plan: 'Aktueller Plan',
			contact_sales: 'Vertrieb kontaktieren',
			get_plan: '{plan} erhalten',
			bill_annually: 'jährlich {amount} berechnet',
			bill_quarterly: '{amount} für 3 Monate berechnet',
			free: {
				free: 'Kostenlos',
				text: 'Text',
				forever: 'Für immer',
			},
			pro: {
				pro: 'Pro',
				text: 'Schalte Premium-Funktionen für professionellere Projekte frei.',
			},
			elite: {
				elite: 'Elite',
				text: 'Für große Projekte mit erweiterten Fähigkeiten.',
			},
			enterprise: {
				enterprise: 'Unternehmen',
				text: 'Fortschrittliche Lösungen und maßgeschneiderte Unterstützung für hochkarätige Projekte.',
				custom: 'Individuell',
			},
			benefits: {
				upto_10_projects: 'Bis zu 10 Projekte',
				monthly_credits_30: '30 monatliche Credits für Super Downloads & Pixcap AI',
				commercial_license: 'Kommerzielle Lizenz (mit Wasserzeichen)',
				all_premiums: 'Alle Premium-Vorlagen & Assets',
				upto_50_projects: 'Bis zu 50 Projekte',
				monthly_credits_600: '600 monatliche Credits für Super Downloads & Pixcap AI',
				super_download: '3x schnellere Super Downloads (Bild)',
				full_commercial_license: 'Volle kommerzielle Lizenz & kein Wasserzeichen',
				export_3d_files: 'Export von 3D-Dateien',
				unlimited_projects: 'Unbegrenzte Projekte',
				monthly_credits_2000: '2000 monatliche Credits für Super Downloads & Pixcap AI',
				dedicated_support: 'Dedizierter Support',
				everything_in_pro: 'Alles in Pro',
				modeling_services: '3D-Modellierungsdienste',
				unlimited_credits: 'Unbegrenzte Credits',
				custom_animations: 'Benutzerdefinierte Animationen',
				custom_mockups: 'Benutzerdefinierte Mockups',
				everything_in_elite: 'Alles in Elite',
			},
			vat_included: 'Mehrwertsteuer (MwSt.) inbegriffen',
			credit_cards: 'Akzeptiert Kredit- und Debitkarten',
			cancel_anytime: 'Jederzeit kündbar',
			trusted_by: 'Vertraut von',
			show_full: 'Vollständig anzeigen',
			hide: 'Ausblenden',
			features: {
				features: 'Funktionen',
				all_premiums: 'Alle Premium 3D-Grafiken (Vorlagen, Icons & Szenen)',
				free_only: 'Nur kostenlos',
				graphics_upload: 'Grafik-Upload',
				fonts_upload: 'Schriftarten-Upload',
				custom_artboard_sizes: 'Benutzerdefinierte Artboard-Größen',
				resolution_4k_export: '4K-Auflösung Exporte',
				file_3d_export: '3D-Datei Exporte (GLTF/GLB)',
			},
			credit_based_features: {
				credit_based_features: 'Kreditbasierte Funktionen',
				super_download: 'Super Download',
				super_download_tooltip:
					'Super Download bietet hochwertiges <br> Ray-Tracing, Schatten und Schärfe <br> für detaillierte und professionelle Bilder/Videos. <br> &#x2022; 1 Kredit pro Bild <br> &#x2022; 1 Kredit pro 0,1 Sekunden Video',
				rendering_speed: 'Rendergeschwindigkeit (für Bilder)',
				pixcap_ai: 'Pixcap KI',
				pixcap_ai_tooltip: '1 Kredit pro KI-Generierung',
				custom_ai_prompt: 'Benutzerdefinierte KI-Eingabeaufforderung',
				private_ai_output: 'Private KI-Ausgaben',
				background_remover: 'Hintergrund-Entferner',
				monthly_credits: 'monatliche Kredite',
				super_and_ai: '(Super Downloads & Pixcap AI)',
				unlimited: 'Unbegrenzt',
				medium: 'Mittel',
				fast: 'Schnell (3x)',
			},
			licensing: {
				licensing: 'Lizenzierung',
				personal_use: 'Persönliche Nutzung',
				commercial_use: 'Kommerzielle Nutzung',
				watermark: 'Wasserzeichen',
			},
			tailored_services: {
				tailored_services: 'Maßgeschneiderte Dienstleistungen',
				modeling_services: '3D-Modellierungsdienste',
				custom_animations: 'Benutzerdefinierte Animationen',
				custom_mockups: 'Benutzerdefinierte Mockups',
				dedicated_support: 'Dedizierter Support',
			},
			support: 'Support',
			faq: {
				faq: 'Häufig gestellte Fragen',
				what_is_super_download: 'Was ist Super Download?',
				what_is_super_download_answer:
					'Bilder und Videos, die im Super-Format exportiert werden, haben eine viel höhere Qualität mit verbessertem Ray Tracing und realistischeren Schatten und Beleuchtung.',
				can_i_use_pixcap_for_free: 'Kann ich Pixcap kostenlos nutzen?',
				can_i_use_pixcap_for_free_answer:
					'Auf jeden Fall! Pixcap bietet einen kostenlosen Plan für jeden. Sie können alle Vorlagen und Inhalte ohne Kosten durchsuchen. Wenn Ihnen ein PRO-Asset oder eine Vorlage gefällt, Sie Ihr KI-Spiel verbessern oder das animierte Mockup-Tool verwenden möchten, können Sie Ihren Plan je nach Bedarf auf Pro oder Elite upgraden. Mit bezahlten Plänen können Sie Super Download, 4K-Download nutzen, GLB-Dateien herunterladen, auf alle Inhalte zugreifen und mit animierten 3D-Mockups experimentieren.',
				will_my_subscription_automatically_renew: 'Wird mein Abonnement automatisch verlängert?',
				will_my_subscription_automatically_renew_answer:
					'Ja, abhängig davon, welchen Plan Sie gewählt haben, monatlich oder jährlich, wird sich Ihr Abonnement entsprechend erneuern, bis Sie es manuell kündigen, was jederzeit möglich ist. Sie haben weiterhin Zugang zu den Vorteilen Ihres Plans bis zum nächsten Abrechnungszyklus.',
				what_are_pixcap_credits: 'Was sind Pixcap Credits?',
				what_are_pixcap_credits_answer: `Pixcap Credits ermöglichen es Ihnen, das volle Potenzial von Pixcap auszuschöpfen! Grundsätzlich benötigen Sie Credits für Super Download, Pixcap AI und Super Download Animation-Exporte, wobei jedes Tool eine bestimmte Menge an Credits verwendet. Zum Beispiel ist 1 statischer Bildexport mit Super Render 1 Credit wert, während 1 Sekunde Export einer Super Render Animation {amount} Credits kostet. Mehr über unsere Credits erfahren Sie auf Pixcap Credits.`,
				how_do_my_pixcap_credits_renew: 'Wie erneuern sich meine Pixcap Credits?',
				how_do_my_pixcap_credits_renew_answer:
					'Ja, jeder kostenlose Benutzer hat 30 kostenlose monatliche Pixcap Credits. Falls Sie mehr Credits benötigen, aber sich nicht zu einem Abonnement verpflichten möchten, können Sie eines unserer Auflade-Pakete erwerben.',
				do_free_users_also_have_pixcap_credits: 'Haben auch kostenlose Benutzer Pixcap Credits?',
				do_free_users_also_have_pixcap_credits_answer:
					'Ihre Pixcap Credits werden monatlich am 1. des Monats zurückgesetzt. Jeder ungenutzte Credit des Vormonats verfällt - stellen Sie also sicher, dass Sie alle verwenden!',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: 'Können Pro- oder Elite-Benutzer auch aus den Auflade-Paketen kaufen?',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'Ja! Wenn Sie alle Ihre Abonnement-Credits aufgebraucht haben, können Sie aus unseren Auflade-Paketen kaufen - Sie haben kein monatliches Limit, Sie können also so viele kaufen, wie Sie benötigen!',
				can_i_use_pixcap_for_commercial_purposes: 'Kann ich Pixcap für kommerzielle Zwecke nutzen?',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'Alle exportierten Inhalte können kommerziell genutzt werden, wobei für KOSTENLOSE Benutzer eine Quellenangabe erforderlich ist. PRO & ELITE Benutzer müssen keine Quellenangabe machen und können alle Pixcap-Inhalte kommerziell in ihren Projekten verwenden.',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription:
					'Verliere ich den Zugriff auf meine Downloads, wenn ich mein Abonnement kündige?',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer:
					'Ihre Downloads bleiben für immer mit Ihrem Konto verbunden, unabhängig vom Abonnement.',
				what_is_your_refund_policy: 'Wie ist Ihre Rückerstattungsrichtlinie?',
				what_is_your_refund_policy_answer:
					'Zahlungen für Pixcap-Abonnements sind nicht erstattungsfähig, es sei denn, Sie leben in der EU oder der Türkei. In diesem Fall können Sie möglicherweise eine Rückerstattung erhalten, wenn Sie Ihr Abonnement innerhalb von 14 Tagen nach dem Kauf kündigen und <span class="text-weight-700">Sie den Dienst nicht genutzt haben, das heißt, Sie haben keine Ressource von Pixcap heruntergeladen.</span>' +
					'<div class="mt-m">Um eine Rückerstattung zu beantragen, senden Sie uns bitte eine E-Mail an <span class="text-color-primary-5 text-underline">support@pixcap.com</span> und geben Sie Ihr Wohnsitzland an.</div>',
			},
			testimonials: {
				testimonials: 'Kundenstimmen',
				header: 'Was unsere Kunden über uns sagen',
			},
			do_more: {
				do_more: 'Mehr erreichen',
				header: 'Upgraden Sie und entdecken Sie eine vollständige Suite leistungsstarker 3D- und KI-Designtools',
				text: 'Pixcap ist eine browserbasierte Plattform, die anpassbare 3D-Symbole, Grafiken und Illustrationen anbietet. Viele unserer Funktionen sind kostenlos und bieten Ihnen eine Komplettlösung für alle Ihre 3D-Bearbeitungsbedürfnisse. Eine Kontoerstellung ist nicht zwingend erforderlich, wird aber empfohlen, da Sie so Ihre kreativen Arbeiten speichern können, um Ihre Icons jederzeit zu bearbeiten und herunterzuladen.',
				upgrade_now: 'Jetzt upgraden',
			},
		},
		user_settings: {
			account_settings: {
				account_settings: 'Kontoeinstellungen',
				display_name: 'Anzeigename',
				bio: 'Biografie',
				bio_placeholder: 'Stelle dich vor',
			},
			invite_friends: {
				invite_friends: 'Freunde einladen',
				header: 'Freunde einladen, sofort Belohnungen erhalten',
				text: 'Teile den Einladungslink mit deinen Freunden und verfolge deine verdienten Belohnungen.',
				invite: 'Einladen',
				by_link: 'Per Link einladen',
				copy: 'Link kopieren',
				by_email: 'Per E-Mail einladen',
				enter_email: 'E-Mail-Adresse eingeben',
				send: 'E-Mail senden',
				how_it_works: 'Wie es funktioniert',
				note: 'Das Pixcap-Empfehlungsprogramm ist beendet. Die unten aufgeführte verdiente Belohnung ist nur relevant für diejenigen, die vor der Schließung am Programm teilgenommen haben.',
				earned_rewards: 'Verdiente Belohnungen',
				referrals: 'Empfehlungen',
				date: 'Datum',
				status: 'Status',
				earnings: 'Verdienste',
				no_rewards: 'Keine Belohnungen verdient.',
			},
			subscription: {
				subscription: 'Abonnement',
				text: 'Verfolge deine Pläne & Guthaben',
				plan: '',
				on_free: 'Du hast jetzt ein KOSTENLOSES Konto',
				sub_expired: '**Dein Abonnement ist abgelaufen.',
				payment_unsucess: '**Deine Zahlung war nicht erfolgreich, bitte versuche erneut zu abonnieren.',
				payment_pending: '**Deine Zahlung steht aus, bitte überprüfe, um die Zahlung fortzusetzen.',
				payment_invalid: '**Deine Zahlung ist ungültig, bitte überprüfe und aktualisiere deine Zahlungsdetails.',
				plan_to_renew: '**Bitte wähle einen Plan zur Erneuerung.',
				check_payment: 'Zahlung überprüfen',
				go_to_payment: 'Zur Zahlung gehen',
				choose_a_plan: 'Plan auswählen',
				complete_payment_in_your_plan: 'Schließe die Zahlung ab, um deinen {plan} fortzusetzen',
				cancel_plan: 'Plan kündigen',
				change_plan: 'Plan ändern',
				plan_end_on: 'Plan endet am',
				next_payment: 'Nächste Zahlung',
				view_all_invoice: 'Alle Rechnungen anzeigen',
				credits: 'Guthaben',
				current_monthly_credits: 'Aktuelles monatliches Abonnement-Guthaben',
				credit_note: 'Diese Guthaben sind Teil deines Abonnements und werden monatlich zurückgesetzt.',
				topup_balance: 'Auflade-Guthaben',
				topup: 'Guthaben aufladen',
				note: 'Guthaben, die du durch Aufladungen erworben hast. Diese Guthaben <br />haben kein monatliches Limit und werden nicht verbraucht, solange <br />deine Abonnement-Guthaben verfügbar sind.',
				per_year: 'Pro Jahr',
				per_month: 'Pro Monat',
				for_3_months: 'Für 3 Monate',
				get_more_create_more: 'Erhalte mehr, erschaffe mehr!',
				save_more: 'Spare mehr mit dem Jahresplan',
				upgrade_plan: 'Plan upgraden',
				unlock_all: 'Alle Funktionen freischalten',
				topup_modal: {
					choose_a_bundle: 'Wähle ein Paket',
					credits: 'Guthaben',
					text: 'Entspricht <strong>1 von 3</strong> Angeboten unten:',
					super_render_images: 'Super Render Bilder',
					super_render_videos: 'Super Render Videos',
					images: '{credits} Bilder',
					seconds: '{credits} Sekunden',
					ai: 'KI-Generierungen',
					generations: '{credits} Generierungen',
					amount: 'Betrag',
					purchase_now: 'Jetzt kaufen',
				},
				cancel_sub_modal: {
					cancel_sub: 'Abonnement kündigen',
					sub: 'Sie verlieren alle Premium-Funktionen, sobald Ihr {plan}-Plan am <strong>{expireDay}</strong> abläuft',
					sub_alt: 'Sie verlieren sofort alle Premium-Funktionen, sobald Sie kündigen.',
					let_us_know: 'Lassen Sie uns wissen, warum Sie gehen',
					write_feedback: 'Schreiben Sie ein Feedback...',
					confirmed: 'Ihre Kündigung ist bestätigt!',
					update_text: 'Es kann einen Moment dauern, bis das Abonnement aktualisiert wird.',
					enjoy:
						'Sie können alle Pixcap {plan}-Funktionen noch bis zum <strong>{expireDay}</strong> genießen. Danach haben Sie keinen Zugriff mehr auf Premium-Funktionen.',
					change_your_mind: 'Wenn Sie Ihre Meinung ändern, erwägen Sie, Ihren Plan in den Abonnement-Einstellungen zu erneuern 😊!',
					sad: 'Wir sind traurig, Sie gehen zu sehen! Sie können Pixcap weiterhin als <strong>KOSTENLOSER</strong> Benutzer genießen.',
					change_your_mind_2: 'Wenn Sie Ihre Meinung ändern, erwägen Sie, sich in den Abonnement-Einstellungen erneut zu abonnieren 😊!',
					keep: 'Pixcap {name} behalten',
					got_it: 'Verstanden',
					reason: {
						reason_1: 'Es ist zu teuer',
						reason_2: 'Ich brauche mehr Funktionen',
						reason_3: 'Ich habe eine andere ähnliche Website gefunden',
						reason_4: 'Ich benutze es nicht so oft',
						reason_5: 'Es gibt nicht viele qualitativ hochwertige Ressourcen',
						reason_6: 'Ich denke nicht, dass Pixcap {plan} das Geld wert ist',
						reason_7: 'Ich habe eine andere ähnliche Website gefunden',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Pro-Quartalsplan',
					elite_quarterly_plan: 'Elite-Quartalsplan',
					free_plan: 'KOSTENLOSER Plan',
					pro_annual_plan: 'Pro-Jahresplan',
					elite_annual_plan: 'Elite-Jahresplan',
					pro_monthly_plan: 'Pro-Monatsplan',
					elite_monthly_plan: 'Elite-Monatsplan',
				},
			},
			promotion: {
				promotion: 'Aktionen',
				text: 'Verfolge Empfehlungsrabatte & wöchentliche Gratisartikel.',
				only_one: 'Nur <span class="text-weight-600">01</span> abonnementbasierte Belohnung pro Abrechnungszeitraum. Finde unten deine Favoriten. 🌟',
				got_it: 'Verstanden',
				no_promotions: 'Es sind keine Aktionen verfügbar',
			},
			change_password: {
				change_password: 'Passwort ändern',
				current: 'Aktuelles Passwort',
				current_placeholder: 'Aktuelles Passwort eingeben',
				new: 'Neues Passwort',
				new_placeholder: 'Neues Passwort eingeben',
				confirm: 'Passwort bestätigen',
				confirm_placeholder: 'Neues Passwort wiederholen',
				error_1: 'Passwort darf nicht leer sein',
				error_2: 'Neues Passwort darf nicht leer sein',
				error_3: 'Passwortbestätigung darf nicht leer sein',
				error_4: 'Die Passwörter stimmen nicht überein',
				error_5: 'Passwort falsch',
			},
			email_notification: {
				email_notification: 'E-Mail-Benachrichtigungen',
				alerts_and_newsletter: 'Warnungen & Newsletter',
				downloads: 'Downloads',
				downloads_text: 'Erhalte Benachrichtigungen, wenn hochwertige & KI-Bilder zum Download verfügbar sind',
				events: 'Veranstaltungen',
				events_text: 'Werde benachrichtigt, wenn deine bevorzugten Veranstaltungspakete veröffentlicht werden',
				newsletters: 'Newsletter',
				newsletters_text: 'Erhalte Marketing-Newsletter für Neuigkeiten & Design-Inspirationen',
				account_activity: 'Kontoaktivität',
				email_when: 'Sende mir eine E-Mail, wenn',
				comments: 'Jemand meinen Projekt kommentiert',
				mentions: 'Jemand mich erwähnt',
				collab_invites: 'Ich Einladungen zur Zusammenarbeit an ihren Projekten erhalte',
				pending_actions: 'Ausstehende Aktionen',
				conversation: 'Gespräch in Projekten',
			},
			save: 'Speichern',
		},
		shared_project: {
			search_placeholder: 'Nach geteiltem Projekt suchen...',
			title: 'Mit mir geteilt',
		},
	},
	payments: {
		method: 'Zahlungsmethoden',
		card_ewallets: 'Karten / E-Wallets',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: '3D-Symbole und animierte Symbole anpassen',
				description:
					'Probieren Sie den 3D-Editor aus, um Farben, Materialien und Animationsstile Ihrer 3D-Symbole anzupassen. Laden Sie kostenlose 3D-Symbole in PNG, GLB, GIF, MP4 für die Verwendung in Figma, Sketch, Adobe und mehr herunter.',
				btn_text: '3D-Symbole entdecken',
			},
			characters: {
				title: '3D-Charaktere zum Leben erwecken',
				description:
					'Entdecken Sie das einzige 3D-Positionierungstool, das es Ihnen ermöglicht, Ihre 3D-Charaktere zu posieren, Farben zu ändern und mehr, ohne 3D-Modellierungskenntnisse erforderlich.',
				btn_text: '3D-Charaktere entdecken',
			},
			mockups: {
				title: 'Animierte 3D-Mockups in Sekunden erstellen',
				description:
					'Mockups sind jetzt voll anpassbar - laden Sie Ihr Label in ein beliebiges animiertes Mockup hoch und laden Sie es in weniger als 5 Minuten in 4K herunter.',
				btn_text: 'Mockups entdecken',
			},
		},
		view_all: 'Alle anzeigen',
		coming_soon: 'Demnächst',
		status: {
			all: 'Alle Status',
			completed: 'Abgeschlossen',
			processing: 'In Bearbeitung',
			failed: 'Fehlgeschlagen',
			cancelled: 'Abgebrochen',
			preparing: 'Vorbereitung',
			removed: 'Entfernt',
		},
		packs_count: '{count} Pakete',
		items_count: '{count} Elemente',
		filter: {
			type: {
				type: 'Typ',
				animated_static: 'Animiert + Statisch',
				animated: 'Animiert',
				static: 'Statisch',
			},
			price: {
				price: 'Preis',
				free_paid: 'Kostenlos + Kostenpflichtig',
				free: 'Kostenlos',
				paid: 'Kostenpflichtig',
			},
			size: {
				size: 'Größe',
				all: 'Alle Größen',
			},
			pack_or_item: {
				items: 'Artikel',
				packs: 'Pakete',
			},
		},

		all_assets: 'Alle Assets',
		get_started: 'Loslegen',
		icons: '3D-Symbole',
		characters: '3D-Charaktere',
		mockups: 'Mockups',
		icon_packs: '3D-Symbol-Pakete',
		character_packs: '3D-Charakter-Pakete',
		mockup_pack: 'Mockup-Pakete',
		new: 'Neu',
		by: 'von',
		get_elite_for_unlimited_access: 'Holen Sie sich Elite für unbegrenzten Zugang',
		upgrade_to_elite: 'Auf Elite upgraden',
		unlock_all_access: 'Vollzugriff freischalten',
		explore_now: 'Jetzt erkunden',

		copy_link: 'Link kopieren',
		link_copied: 'Link kopiert',
		more: 'mehr',

		or: 'oder',
		show_all: 'Alle anzeigen',
		free: 'Kostenlos',
		purchased: 'Gekauft',
		animated: 'Animiert',
		tags: {
			all: 'Alle',
			trending: 'Trending',
		},

		edit_in_3d: 'In 3D bearbeiten',
		view_details: 'Details anzeigen',
		read_more: 'Mehr lesen',
		download: 'Herunterladen',
		open: 'Offen',
		delete_modal: {
			title: '{entity} löschen',
			message: 'Sind Sie sicher, dass Sie diese(s) {entity} löschen möchten?',
			delete: 'Löschen',
		},
		empty: {
			with_search: {
				message: 'Keine Ergebnisse gefunden',
				sub_message: "Möchtest du, dass wir '{search}' {type} für dich entwerfen? Sende uns eine",
			},
			request: 'Anfrage',
			message: 'Ups! Dieser Ort ist im Moment leer.',
			sub_message: 'Versuchen Sie, Ihre Filter anzupassen, oder schauen Sie bald wieder vorbei, um Updates zu sehen',
		},
		cancel: 'Abbrechen',
		error_404: 'Entschuldigung, wir konnten die gesuchte Seite nicht finden.',
		error_500: 'Etwas ist schiefgelaufen, bitte versuche es später erneut.',
		under_maintain: 'Wir führen gerade geplante Wartungsarbeiten durch \n und werden bald wieder online sein. Danke für deine Geduld.',
		error_password_length: 'Das Passwort muss mindestens 8 Zeichen oder Ziffern enthalten (keine Leerzeichen).',
		error_password_not_matched: 'Die Passwortbestätigung stimmt nicht überein.',
		back_to_explore: 'Zurück zum Erkunden',
		retry: 'Erneut versuchen',
		render_type: {
			cloud: 'Cloud-Rendering',
			default: 'Standard-Rendering',
			ai: 'KI-Stil',
		},
		remove: 'Entfernen',
	},
	toast: {
		error: 'Etwas ist schiefgelaufen. Bitte aktualisieren Sie und versuchen Sie es erneut.',
		success_delete_project: `Projekt {name} wurde erfolgreich gelöscht`,
	},
	tag_name: {
		branding: 'Branding',
		brand_kit: 'Marken-Kit',
		device: 'Gerät',
		business: 'Unternehmen',
		casual: 'lässig',
		ecommerce: 'e-Commerce',
		essential: 'Wesentlich',
		fintech: 'fintech',
		healthcare: 'Gesundheitswesen',
		laptop: 'Laptop',
		profession: 'Beruf',
		smartphone: 'Smartphone',
	},
	seo_meta: {
		customizable: 'Anpassbar',
		free: 'Kostenlos',
		animated: 'Animiert',
		text_3d_animated_icons: '3D-animierte Icons',
		text_3d_icons_illus: '3D-Symbole & Illustrationen',
		text_animated_icons: 'animierte Icons',
		text_icons_logos_symbols: 'Icons, Logos, Symbole',
		title_item_detail: `{keyWord} in PNG, GLB, GIF, MP4 herunterladen - Pixcap`,
		description_item_illustration_detail: `Anpassbare {keyWord} für Designprojekte ✓ Über 100 verwandte 3D-Illustrationen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		description_item_character_detail: `Anpassbare {keyWord} für Designprojekte ✓ Über 100 verwandte 3D-Charaktere ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		description_item_icon_detail: `Anpassbare {keyWord} für Designprojekte ✓ Über 100 verwandte 3D-Symbole ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		description_item_mockup_detail: `Anpassbare {keyWord} für Designprojekte ✓ Über 100 verwandte 3D-Mockups ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		description_item_design_detail: `Anpassbare {keyWord} für Designprojekte ✓ Über 100 verwandte 3D-Designs ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		title_brandkit_detail: `Branding-Mockup-Vorlagen für Ihr Unternehmen - Pixcap`,
		description_brandkit_detail: `Erstellen Sie online eine beeindruckende Markenidentität mit unseren kostenlosen und Premium-Branding-Mockup-Vorlagen. Visualisieren Sie Ihr Design auf Logo, Briefpapier, Geräten, Büromaterial und mehr.`,
		title_pack_detail: `{packKeyWord} Icon-Paket | Laden Sie {packSize} 3D-Symbole und -Illustrationen herunter`,
		description_pack_detail: `Anpassbares {packKeyWord} 3D-Symbole-Paket für Designprojekte ✓ Über 100 verwandte Icon-Pakete ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,

		title_library_icons: '{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Icon-Pakete - Kostenloser Download PNG, GLB, GIF, MP4',
		description_library_icons: `Finden Sie {currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Icon-Pakete für Grafikdesign, UX/UI, Präsentationen und mehr ✓ Anpassen, herunterladen und teilen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		title_library_characters: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Charaktere - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_library_characters: `Finden Sie {currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Charaktere für Grafikdesign, UX/UI, Präsentationen und mehr ✓ Anpassen, herunterladen und teilen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		title_library_illusions: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Illustrationen - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_library_illusions: `Finden Sie {currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Illustrationen für Grafikdesign, UX/UI, Präsentationen und mehr ✓ Anpassen, herunterladen und teilen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		title_library_mockups: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Mockups - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_library_mockups: `Finden Sie {currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Mockups für UX/UI, Portfolio, Präsentationen und mehr ✓ Anpassen, herunterladen und teilen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung`,
		title_library_mockups_default: `Erstellen Sie 3D-Mockups und animierte 3D-Mockups in Sekunden`,
		description_library_mockups_default: `Generieren Sie realistische 3D-Mockup-Bilder und -Videos für Ihre Produkte, Poster, Visitenkarten, Buchcover und mehr. Kostenloser Mockup-Generator zur Online-Nutzung.`,
		title_library_designs: '3D-Symbole, Illustrationen, Mockups - Kostenloser Download PNG, GLB, GIF, MP4',
		description_library_designs:
			'Durchsuchen und laden Sie über 10.000 3D-Elemente für Grafikdesign, Präsentationen und Webprojekte herunter ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung.',

		title_search_prefix: ' - Kostenloser Download von PNG, GLB, GIF, MP4',
		title_search_pack_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery}Symbolpakete – Kostenloser Download von PNG, GLB, GIF, MP4`,
		description_search_pack_list: `Passen Sie {currentPlanQuery}{searchWord} 3D {currentTypeQuery} Symbolpaket anpassen und herunterladen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung ✓ Täglich werden neue Pakete hinzugefügt.`,
		title_search_icon_list: `{totalItems} {currentPlanQuery}{searchWord} 3D {currentTypeQuery} Icons – Kostenloser Download von PNG, GLB, GIF, MP4`,
		description_search_icon_list: `{currentPlanQuery}{searchWord} 3D {currentTypeQuery} Icons, Logos, Symbole anpassen und herunterladen ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung ✓ Täglich werden neue Symbole hinzugefügt.`,
		title_search_scene_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery}3D-Illustrationen - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_search_scene_list: `Passen Sie {currentPlanQuery}{searchWord} 3D-{currentTypeQuery}Illustrationen an und laden Sie sie herunter ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung ✓ Täglich neue Illustrationen.`,
		title_search_mockup_list: `{totalItems} {searchWord} 3D-Mockups - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_search_mockup_list: `Passen Sie {searchWord} 3D-Mockups an und laden Sie sie herunter ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung ✓ Täglich neue 3D-Mockups.`,
		title_search_template_list: `{totalItems} {searchWord} 3D-Designs - Kostenloser Download PNG, GLB, GIF, MP4`,
		description_search_template_list: `Passen Sie {searchWord} 3D-Designs an und laden Sie sie herunter ✓ Hohe Auflösung ✓ Kostenlos für kommerzielle Nutzung ✓ Täglich neue 3D-Designs.`,
	},
};

export default de;
