import { AppLang } from '@/lang/_template.type';

const en: AppLang = {
	app_name: 'Pixcap',
	promo_banner: {
		title: 'Enjoy {discount}% off on Pro & Elite Annual plans with promo code: <span class="text-weight-600">{promo_code}!</span>',
		cta_text: 'Get {discount}% Off',
	},
	navbar: {
		search_placeholder: 'Search...',
		search_dropdown: {
			recent_search: 'Recent search',
			popular_search: 'Popular search',
			suggested_search: 'Suggested Search',
		},
		navigation_items: {
			browse: 'Browse',
			asset: '3D Assets',
			asset_dropdown: {
				icons_title: '3D Icons',
				icons_description: '15,000+ free and premium 3D icons & illustrations',
				characters_title: '3D Characters',
				characters_description: '1,000+ free and premium 3D characters',
				mockups_title: 'Mockups',
				mockups_description: '500+ free and premium mockups and branding mockup kits',
			},
			tools: 'Tools',
			tools_dropdown: {
				prefix: 'Best AI Tools',
				ai_3d_model: 'AI 3D Model Generator',
				ai_character: 'AI Character Generator',
				ai_girl: 'AI Girl Generator',
				ai_background: 'AI Background Generator',
				ai_filter: 'AI Filter',
				ai_icon: 'AI Icon Generator',
				icon_maker: '3D Icon Maker',
			},
			pricing: 'Pricing',
		},

		language_switcher: {
			tooltip: 'Language',
		},

		my_projects: 'My Projects',

		login: 'Login',
		notification_dropdown: {
			notifications: 'Notifications',
			empty: 'You haven’t got any notifications',
		},
		download_dropdown: {
			full_download_history: 'Full download history',
			tooltip: {
				download_history: 'Download history',
				download_completed: 'Download completed',
			},
			show_all_downloads: 'Show All Downloads',
			empty: 'It’s empty here. \n Try to Download (High-Quality) \n to see where the magic happens.',
			upgrade: {
				enjoy: 'Did you know Pro/Elite users enjoy <span class="text-color-pro">3x faster renders?</span>',
				text: 'Upgrade now for a high-quality download speed boost!',
				upgrade_now: 'Upgrade Now',
			},
			cancel_download_modal: {
				cancel_download: 'Cancel High-Quality Download',
				text: 'You will lose all the credits used for this video. Are you sure you want to proceed?',
				text_gif: 'You will lose all the credits used for this GIF. Are you sure you want to proceed?',
				confirm: 'Confirm',
			},
		},
		user_dropdown: {
			projects: 'Projects',
			downloads: 'Downloads',
			account_settings: 'Account Settings',
			invite_friends: 'Invite Friends',
			contact_us: 'Contact Us',
			shared_project: 'Shared Projects',
			contributor_files: 'Contributor Files',
			sign_out: 'Sign Out',
		},
		login_form: {
			get_started: 'Get Started for Free',
			text: 'Access 15,000+ customizable 3D animated mockups, 3D icon packs, illustrations and designs!',
			google_login: 'Continue with Google',
			email_login: {
				password: 'Password',
				forgot: 'Forgot your password?',
				email_login: 'Continue with email',
				verify_email: 'Verify email',
				check_email: 'Check your email at <span class="text-color-primary-5">{{ email }}</span> for a code to verify your email.',
				invalid_code: 'Entered code is not valid, try again',
				did_not_received: "Didn't receive the email?",
				error_email: 'Email is invalid',
				error_E02_02_001: 'Email or password is incorrect.',
				error_E02_02_002: 'This email is already in use',
				error_E02_02_003: 'Something went wrong please try again',
				error_default: 'Failed to login please try again',
			},
		},
		forgot_pw_form: {
			forgot_pw: 'Forgot your password? ',
			sub: 'Please enter your email address to reset your password.',
			check_your_email: 'Check your email',
			sub_text: 'We sent you an email with password reset link.',
			did_not_received: "Didn't receive the email?",
			error_email: 'Email is invalid',
			continue: 'Continue',
			back: 'Back',
		},
		contact_us_form: {
			contact_us: 'Contact us',
			name: 'Name',
			enter_your_name: 'Enter your name',
			email: 'Email',
			enter_your_email: 'Enter your Email',
			message: 'Your message',
			write_message: 'Write your message here',
			send: 'Send message',
			error_name: '**Name cannot be empty',
			error_email: '**Email is invalid',
		},
	},
	sidebar: {
		home: 'Home',
		library: 'Library',
		my_projects: 'My Projects',
		my_assets: 'My Assets',
		pricing: 'Pricing',
		unlock_all_features: 'Unlock All Features',
		upgrade_plan: 'Upgrade Plan',
	},
	breadcrumb: {
		home: 'Home',
		mockups: 'Mockups',
		brandkit_mockups: 'Brand Kit Mockups',
		icon_packs: '3D Icon Packs',
		illustrations: 'Illustrations',
		designs: 'Designs',
	},
	footer: {
		title: 'Explore more 3D content',
		searched_title: 'Browse relevant searches',
		seo_section: {
			sort_by: {
				latest: 'Latest',
				trending: 'Trending',
			},
		},
		footer_section: {
			explore: {
				title: 'Explore',
			},
			features: {
				title: 'Features',
				animated_mockup: 'Animated Mockups',
				ai_style_transfer: 'AI Style Transfer',
				character_library: 'Character Library',
				material_library: 'Material Library',
			},
			company: {
				title: 'Company',
				pricing: 'Pricing',
				enterprise: 'Enterprise',
				privacy_policy: 'Privacy Policy',
				terms_of_service: 'Terms of Service',
				contact_us: 'Contact Us',
			},
			resources: {
				title: 'Resources',
				glossary: 'Glossary',
				contributor: 'Become A Contributor',
			},
			tools: {
				title: 'Tools',
				ai_3d_model: 'AI 3D Model Generator',
				ai_character: 'AI Character Generator',
				ai_girl: 'AI Girl Generator',
				ai_background: 'AI Background Generator',
			},
			create: {
				title: 'Create',
				mug_design: 'Mug Design',
				sticker_design: 'Sticker Design',
				friendsgiving_invitation: 'Friendsgiving Invitation',
				poster_mockup: 'Poster Mockup',
				iphone_mockup: 'iPhone Mockup',
			},
			blogs: {
				title: 'Blogs',
				all_blogs: 'All blogs',
			},
			follow_us: {
				title: 'Follow us',
			},
		},
	},

	page: {
		home: {
			title: '3D Assets for Designers',
			description: 'Customize 15,000+ free & premium 3D icons, illustrations, characters and mockups for UX/UI, graphic design, and marketing.',
			partners: {
				title: 'Used by teams at',
			},
			latest_icon_pack: 'Latest 3D icons',
			characters_pack: '3D characters',
			device_mockup_pack: 'Device mockups',
			branding_mockup_pack: 'Branding mockups',
			advertise: {
				title: 'Best 3D design tool for your projects',
				description: 'Edit and use 3D models for web design, mobile apps, marketing and more',
				first_card: {
					title: 'Website & mobile app',
					description:
						'Use 3D elements to enhance the design of your projects. Grab the attention of your users and make your website and app stand out from the competition.',
				},
				second_card: {
					title: 'Animated mockups',
					description: 'Upload your design to any animated mockup, edit the background to your liking, and download it in 4K in less than 5 minutes.',
				},
				third_card: {
					title: 'Presentation',
					description:
						'Leverage 3D characters and 3D icons for presentations. Make your business pitch or educational presentation more engaging and dynamic.',
				},
			},
			compatible: {
				title: 'Compatible with your favorite software',
				description: 'Download Pixcap content in any file type - GLB, MP4, GIF, PNG to use with all your favorite software.',
			},

			banner: {
				title_desktop: `Get started by customizing <br> any 3D content!`,
				title_mobile: `Start editing any <br> 3D content!`,
				button_text: 'Explore now',
			},

			header1: 'All 3D goodies are customizable - Try now!',
			trending_icons: 'Trending Icons',
			essential_icon: 'Essential icon packs',
			essential_illustrations: 'Essential illustration kits',
			essential_mockups: 'Realistic device & branding mockups',

			header2: 'Browse more editable 3D creatives',
			fintech_illustrations: 'Fintech illustrations',
			ecommerce_illustrations: 'Ecommerce illustrations',
			healthcare_illustrations: 'Healthcare illustrations',
			browse_all_illustrations: 'Browse all illustrations',
			business_characters: 'Business characters',
			casual_characters: 'Casual characters',
			profession_characters: 'Profession characters',
			browse_all_characters: 'Browse all characters',
			laptop_mockups: 'Laptop mockups',
			smartphone_mockups: 'Smartphone mockups',
			branding_mockups: 'Branding mockups',
			browse_all_mockups: 'Browse all mockups',
		},
		library: {
			title: {
				icons: 'Customize free  & <br> premium 3D icon packs',
				characters: 'Customize free  & <br> premium 3D characters',
				mockups: 'Customize free & <br> premium mockups',
			},
			description: {
				icons:
					'Choose from {count} high-quality icon sets for website, app, graphic design and marketing. Customize and download icons in PNG, GLB, GIF, and MP4 for design projects.',
				characters:
					'Boost user engagement and storytelling with character illustrations. Customize & use them in social media posts, presentations, explainer videos, product demos, and more.',
				mockups:
					'Enhance your designs and presentations with realistic mockups. Easily customizable and perfect for showcasing products, branding, packaging, and more.',
			},
			header: 'Explore fully customizable 3D content!',
			cards: {
				icons: {
					icons: 'Icons',
					browse_all: 'Browse all 3D icons',
				},
				characters: {
					characters: 'Characters',
					browse_all: 'Browse all 3D characters',
				},
				illustrations: {
					illustrations: 'Illustrations',
					browse_all: 'Browse all 3D illustrations',
				},
				mockups: {
					mockups: 'Mockups',
					branding_kits: 'Branding mockup kits',
					browse_all: 'Browse all realistic 3D mockups',
				},
				designs: {
					designs: 'Designs',
					browse_all: 'Browse all 3D designs',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				"Spark instant inspiration and showcase your brand's design with our library of professional branding mockup templates. From sleek business cards and eye-catching packaging to stunning social media assets and website mockups, we have everything you need to visualize your brand identity in a realistic setting.",
			features: {
				premium_asset: 'All premium assets',
				exclusive_materials: 'Exclusive materials & presets',
				exclusive_poses: 'Exclusive character poses',
				commercial_license: 'Commercial license',
				export: '3D files export',
				edit: 'Edit colors, materials, lighting,...',
				downloads: 'High-quality downloads',
				elite_unlimited_images: 'Unlimited high-quality images',
				elite_video_per_month: '30 high-quality videos per month',
			},
			get_all_access: 'Get all access',

			info: {
				info: 'Info',
				number_of_items: 'Number of items',
				file_type: 'File type',
				compatible: 'Compatible with',
				release: 'Release',
			},
			mockup_pack_description:
				'{pack_name} mockup pack is a collection of high-quality {pack_name} mockups perfect for showcasing your designs in a professional way. <br> The {pack_name} mockup pack allows you to easily customize and present your designs with ease. Simply upload your design, edit object and background to create a realistic mockup. <br> All {pack_name} mockups are available in PNG, GIF, and MP4 for use on various platforms.',
			pack_description:
				'{pack_name} is a set of 3D icons and animated icons perfect for UX/UI, graphic design and marketing. <br> The {pack_name} includes a vast variety of icons for all your needs and maintains a consistent color scheme for a unified look. <br> All {pack_name} icons are available in PNG, GLB, GIF, and MP4 for use on various platforms.',
			character_pack_description:
				'{pack_name} character pack is a set of 3D characters and animated characters perfect for website, animation, graphic design, marketing, and presentation. <br> The pack offers customization options and for each character, allowing you to change their skin tone, hair color, 3D pose and more. <br> All {pack_name} characters are available in PNG, GLB, GIF, and MP4 for use on various platforms.',
			browse_more: 'Browse more editable 3D creatives',
			related_to_this_pack: 'Related to this pack',
		},
		item_details: {
			features: {
				edit: 'Edit colors, materials, lighting,...',
				edit_character: 'Edit poses, colors, materials, lighting,...',
				edit_mockup: 'Replace images, edit colors, materials, lighting,...',
				downloads: 'High-quality downloads',
				export: 'Export 3D files',
				ai: 'Try AI Style Transfer',

				realistic: 'Realistic 3D mockup',
				editable: 'Editable design and background',
				easy: `Easy-to-use tools`,
				high_quality: `High-quality export`,
				free: 'Free for commercial use',
				customize: 'Customizable shapes, colors & materials',
				high_resolution: 'High-resolution 3D design',

				file_types: 'PNG, GLB filetypes',
				free_license: 'Free license for commercial and personal projects',
				ready_made: 'Ready-made and customizable templates',
				easy_switch: 'Easy switch to various sizes',
				file_types_2: 'PNG filetype',
				item_description:
					'{item_name} is the perfect icon for web, app, UX/UI, graphic design and marketing. <br> You have full customization capabilities with {item_name}, including changing colors, materials, and styles to fit your design projects. <br> Download {item_name} in PNG, GLB, GIF, and MP4 and use in Figma, Blender, Adobe, and more.',
				character_description:
					'{item_name} is the perfect character for website, animation, graphic design, marketing, and presentation.<br> You have full customization capabilities with the {item_name}, including changing skin tone, hair color, 3D pose and more. <br> Available in PNG, GLB file formats.',
				mockup_description:
					'This {item_name} mockup is perfect for displaying your branding, logo, website layout and more. <br> You can easily customize the object, background and add your own design elements to make it truly unique. <br> The {item_name} mockup comes in multiple file formats such as PNG, GIF, and MP4 for easy editing and use on various platforms.',
			},

			open_in_mnm: 'Open in Mix-n-match',
			edit_this_asset: 'Edit this asset',

			more_from_this_pack: 'More from this pack',
			people_also_downloaded: 'People also downloaded',
			text_3d_graphic: '{item_name} 3D Graphic',
			text_3d_illustration: '{item_name} 3D Illustration',

			more_from_this_bundle: 'Templates in this bundle',
			related: 'Related {resouce_type}',
		},
		search: {
			prefix_free: 'free',
			prefix_premium: 'premium',
			prefix_free_and_premium: 'free & premium',
			icon_packs: 'icon packs',
			character_packs: 'character packs',
			mockup_packs: 'mockup packs',
			asset_packs: 'asset packs',
			icon: 'icon',
			icons: 'icons',
			character: 'character',
			characters: 'characters',
			mockup: 'mockup',
			mockups: 'mockups',
			asset: 'asset',
			assets: 'assets',

			description: {
				suffix_animated: 'Available in PNG, GLB, GIF, MP4 file formats.',
				suffix_pack: 'Try animated {search} {type} packs to capture users attention.',
				prefix_pack: 'Customize and download',
				suffix_icon_packs: 'packs for your next design projects.',
				suffix_character_packs: 'packs for web, app, UX/UI, graphic design and marketing.',
				suffix_mockup_packs: 'packs for presentations.',
				suffix_asset_packs: 'packs for your next design projects.',
				suffix_item: 'Try animated {search} {type} to capture users attention.',
				prefix_item: 'Customize and download',
				suffix_icon_items: 'icons, illustrations, symbols and logos for your next design projects.',
				suffix_character_items: 'for web, app, UX/UI, graphic design and marketing.',
				suffix_mockup_items: 'for your next design projects.',
				suffix_asset_items: 'for your next design projects.',
			},

			elements: 'elements',
			packs: 'packs',
			illustrations: 'illustrations',
			designs: 'designs',
			Elements: 'Elements',
			Packs: 'Packs',
			Illustrations: 'Illustrations',
			Mockups: 'Mockups',
			Designs: 'Designs',
			empty: 'Don’t worry! Explore other asset types, or',
			request_new: 'request new',
			cant_find: 'Can’t find',
			browse_relevants: 'Browse relevant searches',
		},
		my_projects: {
			title: 'Projects',
			description: 'Easily customize and manage all your projects in one place.',
			header: 'My Projects',
			search_placeholder: 'Search file',
			new_design: 'New Design',
			default: 'Default',
			advanced_editor: 'Advanced Editor',
			all: 'Animated + Static',
			design: 'Static',
			video: 'Animated',
			edited: 'Edited',
			duplicate_loading: 'Duplicating project....',
			sort: {
				sort_by: 'Sort By',
				recent: 'Recent',
				alphabetical: 'Alphabetical',
				oldest: 'Oldest',
			},

			menu: {
				open_in_advanced_editor: 'Open In Advanced Editor',
				duplicate: 'Duplicate',
				duplicate_submission: 'Duplicate as submission',
				rename: 'Rename',
				delete: 'Delete',
			},

			empty_state: {
				title: 'Let’s fill your projects!',
				description: "This page is empty now. It's time to start customizing 3D assets. ",
			},
		},
		my_assets: {
			title: 'Downloads',
			description: 'View all your downloaded assets here. You can re-download your assets at any time.',
			header: 'My Assets',
			search_placeholder: 'Search file',
			sort: {
				sort_by: 'Sort By',
				oldest: 'Oldest',
				newest: 'Newest',
				alphabet_az: 'Alphabet (A-Z)',
			},
			type: {
				all: 'All types',
				default_render: 'Default Render',
				high_quality: 'High-quality',
				ai_generated: 'Ai-Generated',
			},

			downloads: {
				downloads: 'Downloads',
				file: 'File',
				download_status: 'Download status',
				download_date: 'Download date',

				empty_state: {
					title: 'Let’s fill your downloads!',
					description: "This page is empty now. It's time to start downloading cool assets.",

					heading_1: 'Sorry, no result found',
					heading_2: "Sorry, we couldn't find any matches for “{search}”",
					text: "Your Downloads page is feeling a bit lonely now. It's a right time to start exporting cool items.",
					text_search: 'Try adjusting your search or filter to find what you’re looking for.',
					button: 'Explore more',
				},
			},
			purchases: {
				purchases: 'purchases',
				file: 'File',
				purchase_date: 'Purchase Date',
				open_file: 'Open File',
				empty_state: {
					title: 'Your purchase is empty.',
					text: 'Let’s fill this space with your items.',
					heading_1: 'Sorry, no result found',
					heading_2: "Sorry, we couldn't find any matches for “{search}”",
					text_search: 'Try adjusting your search or filter to find what you’re looking for.',
					button: 'Explore more',
				},
				order_no: 'Order number',
			},
		},
		upgrade_plan: {
			discount_text: 'Enjoy {discount_program}: {discount}% Off on your plan!',
			end_in: 'Limited-time offer ends in',
			header: 'Enabling 3D for Brands, Agencies, and Startups of Every Scale',
			is_pro: 'Thank you for being a valued subscriber! You are enjoying our old, lower prices until your next renewal at the new rate.',
			monthly: 'Monthly',
			quarterly: '3 Months',
			yearly: 'Yearly',
			save_up: 'save up to 33%',
			per_month: '/month',
			recommended: 'Recommended',
			current_short: 'Current',
			current_plan: 'Current plan',
			contact_sales: 'Contact Sales',
			get_plan: 'Get {plan}',
			bill_annually: 'billed {amount} annually',
			bill_quarterly: 'billed {amount} for 3 months',
			free: {
				free: 'Free',
				text: 'Text',
				forever: 'Forever',
			},
			pro: {
				pro: 'Pro',
				text: 'Unlock premium features for a more professional projects.',
			},
			elite: {
				elite: 'Elite',
				text: 'For large projects with enhanced capabilities.',
			},
			enterprise: {
				enterprise: 'Enterprise',
				text: 'Advanced solutions and tailored support for high-stakes projects.',
				custom: 'Custom',
			},
			benefits: {
				upto_10_projects: 'Up to 10 projects',
				monthly_credits_30: '30 monthly credits on Super Downloads & Pixcap AI',
				commercial_license: 'Commercial license (with watermark)',
				all_premiums: 'All premium templates & assets',
				upto_50_projects: 'Up to 50 projects',
				monthly_credits_600: '600 monthly credits on Super Downloads & Pixcap AI',
				super_download: '3x-faster Super Downloads (image)',
				full_commercial_license: 'Full commercial license & no watermark',
				export_3d_files: 'Export 3D files',
				unlimited_projects: 'Unlimited projects',
				monthly_credits_2000: '2000 monthly credits on Super Downloads & Pixcap AI',
				dedicated_support: 'Dedicated support',
				everything_in_pro: 'Everything in Pro',
				modeling_services: '3D modeling services',
				unlimited_credits: 'Unlimited credits',
				custom_animations: 'Custom animations',
				custom_mockups: 'Custom mockups',
				everything_in_elite: 'Everything in Elite',
			},
			vat_included: 'Value Added Tax (VAT) included',
			credit_cards: 'Accept credit and debit cards',
			cancel_anytime: 'Cancel anytime',
			trusted_by: 'Trusted by',
			show_full: 'Show Full',
			hide: 'Hide',
			features: {
				features: 'Features',
				all_premiums: 'All premium 3D graphics (templates, icons & scenes)',
				free_only: 'Free only',
				graphics_upload: 'Graphics upload',
				fonts_upload: 'Fonts upload',
				custom_artboard_sizes: 'Custom arboard sizes',
				resolution_4k_export: '4K resolution exports',
				file_3d_export: '3D file exports (GLTF/GLB)',
			},
			credit_based_features: {
				credit_based_features: 'Credit-based features',
				super_download: 'Super Download',
				super_download_tooltip:
					'Super Download provide high-quality <br> ray-tracing, shadows, and sharpness <br> for detailed and professional images/videos. <br> &#x2022; 1 credit per image <br>  &#x2022; 1 credit per 0.1 second of video',
				rendering_speed: 'Rendering speed (for image)',
				pixcap_ai: 'Pixcap AI',
				pixcap_ai_tooltip: '1 credit per AI generation',
				custom_ai_prompt: 'Custom Ai prompt',
				private_ai_output: 'Private Ai outputs',
				background_remover: 'Background remover',
				monthly_credits: 'monthly credits',
				super_and_ai: '(Super Downloads & Pixcap AI)',
				unlimited: 'Unlimited',
				medium: 'Medium',
				fast: 'Fast (3x)',
			},
			licensing: {
				licensing: 'Licensing',
				personal_use: 'Personal use',
				commercial_use: 'Commercial use',
				watermark: 'Watermark',
			},
			tailored_services: {
				tailored_services: 'Tailored Services',
				modeling_services: '3D modeling services',
				custom_animations: 'Custom animations',
				custom_mockups: 'Custom mockups',
				dedicated_support: 'Dedicated support',
			},
			support: 'Support',
			faq: {
				faq: 'Frequently Asked Questions',
				what_is_super_download: 'What is Super Download?',
				what_is_super_download_answer:
					'Images and videos exported in the Super format will have much higher quality, featuring improved ray tracing, and more realistic shadows and lighting.',
				can_i_use_pixcap_for_free: 'Can I use Pixcap for free?',
				can_i_use_pixcap_for_free_answer:
					'Certainly! Pixcap offers a free plan for everyone. You get to browse all templates and content without any cost. If you like a PRO asset, or template, want to level up your AI game, or use the animated mockup tool, you can to upgrade your plan to Pro or Elite depending on your needs. With paid plans, you can use super download 4k download, download GLB files, access all content, and experiment with animated 3D mockups. ',
				will_my_subscription_automatically_renew: 'Will my subscription automatically renew?',
				will_my_subscription_automatically_renew_answer:
					'Yes, depending on which plan, monthly or annual, your subscription will renew accordingly until you cancel it manually, any time. You will still have access to your plan benefits until your next billing cycle.',
				what_are_pixcap_credits: 'What are Pixcap Credits?',
				what_are_pixcap_credits_answer: `Pixcap Credits allow you to experiment with the full potential of Pixcap! Basically, you need credits for Super Download, Pixcap AI, and Super Download Animation exports and each tool uses a specific amount of credits. For example 1 static image export using super render is worth 1 credit, while  1 second export of super render animation is {amount} credits. You can learn more about our credits on Pixcap Credits.`,
				how_do_my_pixcap_credits_renew: 'How do my Pixcap Credits renew?',
				how_do_my_pixcap_credits_renew_answer:
					'Yes, each free user has 30 free monthly Pixcap Credits. In case you need more credits but do not want to commit to a subscription, you can purchase one of our Top Up Bundles.',
				do_free_users_also_have_pixcap_credits: 'Do free users also have Pixcap Credits?',
				do_free_users_also_have_pixcap_credits_answer:
					'Your Pixcap Credits reset on a monthly basis on the 1st of the month. Every unused credit from the previous month will be lost - so make sure you use them all!',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: 'Can Pro or Elite users also buy from the Top Up Bundles?',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'Yes! If you used up all your subscription credits, you can purchase from our Top Up Bundles - you do not have a monthly limit, so you can buy as many as you need!',
				can_i_use_pixcap_for_commercial_purposes: 'Can I use Pixcap for commercial purposes?',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'All exported content can be used commercially, with attribution required for FREE users. PRO & ELITE users do not have to provide attribution and can use all of Pixcap content commercially in their projects.',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription: 'Will I lose access to my downloads if I cancel my subscription?',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer:
					'Your downloads will be with your account forever, regardless of subscription. ',
				what_is_your_refund_policy: 'What is your refund policy?',
				what_is_your_refund_policy_answer:
					'Payments for Pixcap subscriptions are non-refundable unless you live in the E.U. or Turkey, in which case you may be eligible for a refund only if you cancel your subscription within 14 days of purchase and <span class="text-weight-700"> you didn\'t get to use the service, that is, you haven\'t downloaded any resource from Pixcap.</span>' +
					'<div class="mt-m">To request a refund, please send us an email to <span class="text-color-primary-5 text-underline">support@pixcap.com</span> and include your country of residence. </div>',
			},
			testimonials: {
				testimonials: 'Testimonials',
				header: 'What our clients say about us',
			},
			do_more: {
				do_more: 'Do More',
				header: 'Upgrade and explore a full suite of powerful 3D & AI design tools',
				text: 'Pixcap is a browser-based platform that offers customizable 3D icons, graphics, illustrations. Many of our features are free, giving you a one-stop-shop for all your 3D editing needs. Account creation is not mandatory but recommended as it lets you store your creative work so you can edit and download your icons anytime.',
				upgrade_now: 'Upgrade now',
			},
		},
		user_settings: {
			account_settings: {
				account_settings: 'Account settings',
				display_name: 'Display Name',
				bio: 'Bio',
				bio_placeholder: 'Introduce yourself',
			},
			invite_friends: {
				invite_friends: 'Invite friends',
				header: 'Invite friends, get rewards instantly',
				text: 'Share the invitation link with your friends and track your earned rewards.',
				invite: 'Invite',
				by_link: 'Invite by link',
				copy: 'Copy link',
				by_email: 'Invite by email',
				enter_email: 'Enter email address',
				send: 'Send email',
				how_it_works: 'How it works',
				note: 'The Pixcap Referral Program has ended. The earned reward below is only relevant for those who participated in the program prior to its closure.',
				earned_rewards: 'Earned rewards',
				referrals: 'Referrals',
				date: 'Date',
				status: 'Status',
				earnings: 'Earnings',
				no_rewards: 'No rewards earned.',
			},
			subscription: {
				subscription: 'Subscription',
				text: 'Track your plans & credits',
				plan: 'Plan',
				on_free: "You're now on FREE account",
				sub_expired: '**Your subscription has expired.',
				payment_unsucess: '**Your payment was unsuccessful, please try subscribing again.',
				payment_pending: '**Your payment is pending, please check to continue the payment.',
				payment_invalid: '**Your payment is invalid, please check and update your payment details.',
				plan_to_renew: '**Please choose a plan to renew.',
				check_payment: 'Check payment',
				go_to_payment: 'Go to payment',
				choose_a_plan: 'Choose a plan',
				complete_payment_in_your_plan: 'Complete the payment to continue your {plan}',
				cancel_plan: 'Cancel Plan',
				change_plan: 'Change Plan',
				plan_end_on: 'Plan ends on',
				next_payment: 'Next payment',
				view_all_invoice: 'View all invoices',
				credits: 'Credits',
				current_monthly_credits: 'Current monthly subscription credit balance',
				credit_note: 'These credits are part of your subscription which reset monthly.',
				topup_balance: 'Top-up credit balance',
				topup: 'Top up credits',
				note: 'Credits you earned through top-ups. These credits won’t <br />have a monthly limit and won’t be spent while your <br />subscription credits are available.',
				per_year: 'Per year',
				per_month: 'Per month',
				for_3_months: 'For 3 months',
				get_more_create_more: 'Get more, create more!',
				save_more: 'Save more with Annual plan',
				upgrade_plan: 'Upgrade Plan',
				unlock_all: 'Unlock All Features',
				topup_modal: {
					choose_a_bundle: 'Choose a bundle',
					credits: 'Credits',
					text: 'Equivalent to <strong>1 of 3</strong> offers below:',
					super_render_images: 'Super Render images',
					super_render_videos: 'Super Render videos',
					images: '{credits} images',
					seconds: '{credits} seconds',
					ai: 'AI generations',
					generations: '{credits} generations',
					amount: 'Amount',
					purchase_now: 'Purchase now',
				},
				cancel_sub_modal: {
					cancel_sub: 'Cancel subscription',
					sub: 'You’ll lose all premium features once your {plan} plan expires on <strong>{expireDay}</strong>',
					sub_alt: 'You’ll immediately lose all premium features once you cancel.',
					let_us_know: "Let us know why you're leaving",
					write_feedback: 'Write some feedback...',
					confirmed: 'Your cancellation is confirmed!',
					update_text: 'Subscription may take a few moments to update.',
					enjoy:
						'You can still enjoy all Pixcap {plan} features until <strong>{expireDay}</strong>. After that, you will no longer have access to any premium features.',
					change_your_mind: 'If you change your mind, consider renewing your plan in Subscription Settings 😊!',
					sad: "We're sad to see you go! You may still enjoy Pixcap as a <strong>FREE</strong> user.",
					change_your_mind_2: 'If you change your mind, consider subscribing again in Subscription Settings 😊!',
					keep: 'Keep Pixcap {name}',
					got_it: 'Got it',
					reason: {
						reason_1: 'It’s too expensive',
						reason_2: 'I need more features',
						reason_3: 'I found another similar website',
						reason_4: 'I don’t use it that often',
						reason_5: 'There are not many quality resources',
						reason_6: `I don't think Pixcap {plan} is worth the money`,
						reason_7: 'I found another similar website',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Pro Quarterly Plan',
					elite_quarterly_plan: 'Elite Quarterly Plan',
					free_plan: 'FREE Plan.',
					pro_annual_plan: 'Pro Annual Plan',
					elite_annual_plan: 'Elite Annual Plan',
					pro_monthly_plan: 'Pro Monthly Plan',
					elite_monthly_plan: 'Elite Monthly Plan',
				},
			},
			promotion: {
				promotion: 'Promotions',
				text: 'Track referral discounts & weekly freebies.',
				only_one: 'Only <span class="text-weight-600">01</span> subscription-based reward per billing. Find your favorite below. 🌟',
				got_it: 'Got it',
				no_promotions: "There're no promotions available",
			},
			change_password: {
				change_password: 'Change Password',
				current: 'Current password',
				current_placeholder: 'Enter current password',
				new: 'New password',
				new_placeholder: 'Enter new password',
				confirm: 'Password confirmation',
				confirm_placeholder: 'Re-type new password',
				error_1: 'Password cannot be empty',
				error_2: 'New password cannot be empty',
				error_3: 'Password confirmation cannot be empty',
				error_4: 'The passwords do not match',
				error_5: 'Password incorrect',
			},
			email_notification: {
				email_notification: 'Email Notifications',
				alerts_and_newsletter: 'Alerts & Newsletter',
				downloads: 'Downloads',
				downloads_text: 'Receive notifications when the high-quality & AI images are available for download',
				events: 'Events',
				events_text: 'Get notified when your preferred event packs are published',
				newsletters: 'Newsletters',
				newsletters_text: 'Get marketing newsletters for news & design inspirationss',
				account_activity: 'Account activity',
				email_when: 'Email me when',
				comments: 'Someone comments on my project',
				mentions: 'Someone mentions me',
				collab_invites: 'I receive invitations to collaborate on their projects',
				pending_actions: 'Pending actions',
				conversation: 'Conversation in projects',
			},
			save: 'Save',
		},
		shared_project: {
			search_placeholder: 'Search for shared project...',
			title: 'Shared with me',
		},
	},
	payments: {
		method: 'Payment Methods',
		card_ewallets: 'Cards / eWallets',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: 'Customize 3D icons and animated icons',
				description:
					'Try 3D Editor to adjust colors, materials, and animation styles of your 3D icons. Download free 3D icons in PNG, GLB, GIF, MP4 for use on Figma, Sketch, Adobe and more.',
				btn_text: 'Explore 3D Icons',
			},
			characters: {
				title: 'Bring 3D characters to life',
				description:
					'Discover the only 3D posing tool that allows you to pose your 3D characters, modify colors & more, without the need for 3D modeling expertise.',
				btn_text: 'Explore 3D Characters',
			},
			mockups: {
				title: 'Create animated 3D mockups in seconds',
				description: 'Mockups are now fully customizable - upload your label to any animated mockup, and download it in 4K in less than 5 minutes.',
				btn_text: 'Explore Mockups',
			},
		},
		status: {
			all: 'All status',
			completed: 'Completed',
			processing: 'Processing',
			failed: 'Failed',
			cancelled: 'Cancelled',
			preparing: 'Preparing',
			removed: 'Removed',
		},
		packs_count: '{count} packs',
		items_count: '{count} items',
		filter: {
			type: {
				type: 'Type',
				animated_static: 'Animated + Static',
				animated: 'Animated',
				static: 'Static',
			},
			price: {
				price: 'Price',
				free_paid: 'Free + Paid',
				free: 'Free',
				paid: 'Paid',
			},
			size: {
				size: 'Size',
				all: 'All Size',
			},
			pack_or_item: {
				items: 'Items',
				packs: 'Packs',
			},
		},

		all_assets: 'All assets',
		icons: '3D Icons',
		characters: '3D Characters',
		mockups: 'Mockups',

		icon_packs: '3D Icon Packs',
		character_packs: '3D Character Packs',
		mockup_pack: 'Mockup Packs',

		get_elite_for_unlimited_access: 'Get Elite for unlimited access',
		upgrade_to_elite: 'Upgrade to Elite',
		unlock_all_access: 'Unlock all access',
		explore_now: 'Explore now',
		by: 'by',
		new: 'New',
		copy_link: 'Copy link',
		link_copied: 'Link copied',

		or: 'or',
		show_all: 'Show All',
		view_all: 'View all',
		free: 'Free',
		purchased: 'Purchased',
		animated: 'Animated',
		tags: {
			all: 'All',
			trending: 'Trending',
		},
		coming_soon: 'Coming soon',
		get_started: 'Get started',

		more: 'more',
		edit_in_3d: 'Edit in 3D',
		view_details: 'View Details',
		read_more: 'Read more',
		download: 'Download',
		open: 'Open',

		delete_modal: {
			title: 'Delete {entity}',
			message: 'Are you sure you want to delete this {entity}?',
			delete: 'Delete',
		},
		empty: {
			with_search: {
				message: 'No results found',
				sub_message: "Want us to design '{search}' {type} for you? Send us a",
			},
			request: 'Request',
			message: 'Oops! This place is empty for now.',
			sub_message: 'Try adjusting your filters, or check back soon for updates',
		},
		cancel: 'Cancel',
		error_404: 'Sorry, we couldn’t find the page you’re looking for.',
		error_500: 'Something went wrong, please try again later.',
		under_maintain: "We're currently performing scheduled maintenance \n and will be back online soon, thank you for your patience.",
		error_password_length: 'Password requires at least 8 characters or numbers (no spaces).',
		error_password_not_matched: 'The password confirmation does not match.',
		back_to_explore: 'Back to Explore',
		retry: 'Retry',
		render_type: {
			cloud: 'Cloud Render',
			default: 'Default Render',
			ai: 'AI Style',
		},
		remove: 'Remove',
	},
	toast: {
		error: 'Something went wrong. Please refresh and try again.',
		success_delete_project: `Project {name} deleted successfully`,
	},
	tag_name: {
		essential: 'essential',
		brand_kit: 'brand kit',
		device: 'device',
		fintech: 'fintech',
		ecommerce: 'e-commerce',
		healthcare: 'healthcare',
		business: 'business',
		casual: 'casual',
		profession: 'profession',
		laptop: 'laptop',
		smartphone: 'smartphone',
		branding: 'branding',
	},
	seo_meta: {
		customizable: 'Customizable',
		free: 'Free',
		animated: 'Animated',
		text_3d_animated_icons: '3D Animated Icons',
		text_3d_icons_illus: '3D Icons & Illustrations',
		text_animated_icons: 'animated icons',
		text_icons_logos_symbols: 'icons, logos, symbols',

		title_item_detail: `Download {keyWord} in PNG, GLB, GIF, MP4 - Pixcap`,
		description_item_illustration_detail: `Customizable {keyWord} for design projects ✓ 100+ related 3D illustrations ✓ High resolution ✓ Free for commercial use`,
		description_item_character_detail: `Customizable {keyWord} for design projects ✓ 100+ related 3D characters ✓ High resolution ✓ Free for commercial use`,
		description_item_icon_detail: `Customizable {keyWord} for design projects ✓ 100+ related 3D icons ✓ High resolution ✓ Free for commercial use`,
		description_item_mockup_detail: `Customizable {keyWord} for design projects ✓ 100+ related 3D mockups ✓ High resolution ✓ Free for commercial use`,
		description_item_design_detail: `Customizable {keyWord} for design projects ✓ 100+ related 3D designs ✓ High resolution ✓ Free for commercial use`,

		title_brandkit_detail: `Branding Mockup Templates for Your Business - Pixcap`,
		description_brandkit_detail: `Create a stunning brand identity online with our free & premium branding mockup templates. Visualize your design on logo, stationery, devices, office supplies & more.`,
		title_pack_detail: `{packKeyWord} Icon Pack | Download {packSize} 3D icons & illustrations`,
		description_pack_detail: `Customizable {packKeyWord} 3D icon pack for design projects ✓ 100+ related icon packs ✓ High resolution ✓ Free for commercial use`,

		title_library_icons: '{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Icon Packs - Free download PNG, GLB, GIF, MP4',
		description_library_icons: `Find{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}icon packs for graphic design, UX/UI, presentations and more ✓ Customize, download, and share ✓ High resolution ✓ Free for commercial use`,
		title_library_characters: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Characters - Free download PNG, GLB, GIF, MP4`,
		description_library_characters: `Find{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}characters for graphic design, UX/UI, presentations and more ✓ Customize, download, and share ✓ High resolution ✓ Free for commercial use`,
		title_library_illusions: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Illustrations - Free download PNG, GLB, GIF, MP4`,
		description_library_illusions: `Find{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}illustrations for graphic design, UX/UI, presentations and more ✓ Customize, download, and share ✓ High resolution ✓ Free for commercial use`,
		title_library_mockups: `{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}Mockups - Free Download PNG, GLB, GIF, MP4`,
		description_library_mockups: `Find{currentPlanQuery}{currentTagQuery} 3D{currentTypeQuery}mockups for UX/UI, portfolio, presentations and more ✓ Customize, download, and share ✓ High resolution ✓ Free for commercial use`,
		title_library_mockups_default: `Make 3D Mockups & Animated 3D Mockups in Seconds`,
		description_library_mockups_default: `Generate realistic 3D mockup images and videos for your products, posters, business cards, book covers and more. Free mockup generator to use online.`,
		title_library_designs: '3D Icons, Illustrations, Mockups - Free Download PNG, GLB, GIF, MP4',
		description_library_designs:
			'Browse and download 10,000+ 3D elements for graphic design, presentations, and web projects ✓ High resolution ✓ Free for commercial use.',

		title_search_prefix: ' - Free Download PNG, GLB, GIF, MP4',
		title_search_pack_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} 3D Icon Packs - Free Download PNG, GLB, GIF, MP4`,
		description_search_pack_list: `Customize & download {currentPlanQuery}{searchWord} 3D {currentTypeQuery}icon pack ✓ High resolution ✓ Free for commercial use ✓ New packs added everyday.`,
		title_search_icon_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} - Free Download PNG, GLB, GIF, MP4`,
		description_search_icon_list: `Customize & download {currentPlanQuery}{searchWord} 3D {currentTypeQuery} ✓ High resolution ✓ Free for commercial use ✓ New packs added everyday.`,
		title_search_scene_list: `{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery}3D Illustrations - Free Download PNG, GLB, GIF, MP4`,
		description_search_scene_list: `Customize & download {currentPlanQuery}{searchWord} 3D {currentTypeQuery}illustrations ✓ High resolution ✓ Free for commercial use ✓ New packs added everyday.`,
		title_search_mockup_list: `{totalItems} {searchWord} 3D Mockups - Free Download PNG, GLB, GIF, MP4`,
		description_search_mockup_list: `Customize & download {searchWord} 3D mockups ✓ High resolution ✓ Free for commercial use ✓ New 3D mockups added everyday.`,
		title_search_template_list: `{totalItems} {searchWord} 3D Designs - Free Download PNG, GLB, GIF, MP4`,
		description_search_template_list: `Customize & download {searchWord} 3D designs ✓ High resolution ✓ Free for commercial use ✓ New 3D designs added everyday.`,
	},
};

export default en;
